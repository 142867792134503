import React, { useEffect, useState, ReactNode } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faFileExcel,
  faFilePdf,
  faEdit,
  faTrashAlt,
  faInfoCircle,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface PermissionWrapperProps {
  screenId: string;
  children: ReactNode;
}

interface PermissionButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  permission: string;
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info';
  icon?: 'plus' | 'fileExcel' | 'filePdf' | 'edit' | 'trashAlt' | 'infoCircle';
}

interface PermissionTableProps
  extends React.TableHTMLAttributes<HTMLTableElement> {
  permission: string;
}

const iconMap: { [key: string]: IconDefinition } = {
  plus: faPlus,
  fileExcel: faFileExcel,
  filePdf: faFilePdf,
  edit: faEdit,
  trashAlt: faTrashAlt,
  infoCircle: faInfoCircle,
};

const PermissionButton: React.FC<PermissionButtonProps> = ({
  children,
  permission,
  variant = 'primary',
  icon,
  className = '',
  ...props
}) => {
  const buttonClasses = `btn btn-${variant} ${className}`;
  const hasPermission = usePermissionCheck(permission);

  const button = (
    <button className={buttonClasses} {...props} disabled={!hasPermission}>
      {icon && <FontAwesomeIcon icon={iconMap[icon]} className="me-2" />}
      {children}
    </button>
  );

  if (!hasPermission) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={`tooltip-${permission}`}>
            Você não tem permissão para esta ação.
          </Tooltip>
        }
      >
        <span className="d-inline-block">{button}</span>
      </OverlayTrigger>
    );
  }

  return button;
};

const PermissionTable: React.FC<PermissionTableProps> = ({
  children,
  permission,
  className = '',
  ...props
}) => {
  const hasPermission = usePermissionCheck(permission);
  return hasPermission ? (
    <table className={`table ${className}`} {...props}>
      {children}
    </table>
  ) : null;
};

const usePermissionCheck = (permission: string) => {
  const context = React.useContext(PermissionContext);
  return context.hasPermission(permission);
};

const PermissionContext = React.createContext<{
  hasPermission: (permission: string) => boolean;
}>({
  hasPermission: () => false,
});

const PermissionWrapper: React.FC<PermissionWrapperProps> = ({
  screenId,
  children,
}) => {
  const [userPermissions, setUserPermissions] = useState<string[]>([]);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const token = sessionStorage.getItem('authToken');
        const userId = sessionStorage.getItem('userId');
        const response = await axios.get<{ permissions: string[] }>(
          `${
            import.meta.env.VITE_APP_API_URL
          }/screens/${screenId}/permissions/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserPermissions(response.data.permissions || []);
      } catch (error) {
        console.error('Erro ao obter as permissões do usuário:', error);
      }
    };

    fetchUserPermissions();
  }, [screenId]);

  const hasPermission = (permission: string): boolean => {
    return userPermissions.includes(permission);
  };

  return (
    <PermissionContext.Provider value={{ hasPermission }}>
      {children}
    </PermissionContext.Provider>
  );
};

export { PermissionWrapper, PermissionButton, PermissionTable };
